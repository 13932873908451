@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto Condensed";
  scroll-behavior: smooth;
}
/* width */
::-webkit-scrollbar {
  width: 0.001px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* utility classes */
.small__font {
  font-family: "Times New Roman", Times, serif;
  font-size: 60%;
}

.font__roboto-condensed {
  font-family: "Roboto Condensed", sans-serif !important;
}

.font__mochiy-pop-one {
  font-family: "Mochiy Pop One", sans-serif !important;
}
.backdrop__filter {
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  /* filter: blur(10px); */
  -webkit-backdrop-filter: blur(24px) !important;
  backdrop-filter: blur(24px) !important;
  background-color: #ffffff77;
}
.other__children__filter * {
  filter: blur(0px) !important;
  -webkit-backdrop-filter: blur(0px) !important;
  backdrop-filter: blur(0px) !important;
}
/* --------------------for swiper js --------------------*/
/* .swiper-slide {
  background: red;
  height: 500px;
  width: 500px;
} */

.swiper-button-prev::after,
.swiper-button-next::after {
  color: white !important;
}

.splide__arrow {
  background-color: transparent !important;
}
.splide__arrow * {
  color: white !important;
}

.splide__arrow svg{
  fill: #fff !important;
}
.splide__arrow--next {
  position: relative;
  right: -28px !important;
}
.splide__arrow--prev {
  position: relative;
  left: -28px !important;
}

/* desktop media query */

@media (min-width: 768px) {
  .splide__arrow--next {
    position: relative;
    right: -40px !important;
  }
  .splide__arrow--prev {
    position: relative;
    left: -40px !important;
  }
}


